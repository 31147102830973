import React from 'react';
import ReactDOM from 'react-dom/client';

import { BoardContextProvider, SocketContextProvider, UserContextProvider } from 'context';
import { AppRouter } from 'router/AppRouter';

import reportWebVitals from './reportWebVitals';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BoardContextProvider>
      <SocketContextProvider>
        <UserContextProvider>
          <AppRouter />
        </UserContextProvider>
      </SocketContextProvider>
    </BoardContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
